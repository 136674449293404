import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../../layouts/layout';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Blog from '../../models/Blog';
import './blogs.scss';

const QUERIES = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___order, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 200, truncate: true)
          frontmatter {
            title
            slug
            date(formatString: "D MMM YYYY")
            banner {
              childImageSharp {
                gatsbyImageData(width: 640, layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

interface QueryResults {
  allMarkdownRemark: { edges: { node: Blog }[] };
}

const Blogs = (): JSX.Element => {
  const {
    allMarkdownRemark: { edges },
  }: QueryResults = useStaticQuery(QUERIES);

  return (
    <Layout title="Blog" path="blogs">
      <Header />
      <div className="blogs">
        <div className="blogs-container">
          <h2>BLOG</h2>
          <div className="blogs-list">
            {edges.map(({ node: { id, excerpt, frontmatter: { title, slug, banner, date } } }) => (
              <Link className="blog-card" key={id} to={slug}>
                {!!banner?.childImageSharp && (
                  <div className="blog-card-banner">
                    <GatsbyImage image={banner.childImageSharp.gatsbyImageData} className="blog-card-img" alt={title} />
                  </div>
                )}
                <p className="blog-card-text">{date}</p>
                <p className="blog-card-title">{title}</p>
                <div className="blog-card-text">{excerpt ?? ''}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Blogs;
